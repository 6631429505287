import './groups.styles.scss';
import { useParams } from 'react-router-dom';
import GroupsTable from '../tables/groups-table/groups.table.component';
import { useContext } from 'react';
import { TableContext } from '../../contexts/table.context';


const GroupsPage = () => {

    let { zoneID } = useParams();
    const { selectedZoneData } = useContext(TableContext);


    return (
        <div className='groups-container'>
            <h1>{selectedZoneData || localStorage.getItem('selectedZoneData')}</h1>
            <GroupsTable zoneID={zoneID as string} />
        </div>
    )

}

export default GroupsPage;