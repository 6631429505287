import { Outlet, useNavigate } from 'react-router-dom';
import HeaderBar from '../../components/header-bar/header-bar.component';
import SidePanel from '../../components/side-panel/side-panel.component';
import './main-page.styles.scss';
import React, { useContext, useEffect } from 'react';
import { Auth } from "aws-amplify";
import { AuthContext } from '../../contexts/auth.context';



const MainPage = () => {

    const navigate = useNavigate();


    useEffect(() => {
        checkAuthState();
    }, [])

    const {
        setIsAutenticated,
        userSessionObject,
        setIsChangingEmail,
        setCognitoUserId,
      } = useContext(AuthContext);


    const checkAuthState = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsAutenticated(true);
        } catch (error) {
            setIsAutenticated(false);
            navigate("/signin");
        }
    };


    return (
        <div className='mainpage-container'>
            <SidePanel />
            <div className='mainpage-sub-container'>
                <HeaderBar />
                <Outlet />
            </div>
        </div>
    )

}

export default MainPage;