
import React from 'react';
import { useState, useEffect } from 'react';
import './orders.table.style.scss'
import { DataGrid, GridColDef, GridEventListener, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { getOrdersData } from '../../../services/api/data.utils';
import LoadingSpinner from '../../mui/spinner.component';

const columns: GridColDef[] = [
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        editable: true,
    },
    {
        field: 'orderNumber',
        headerName: 'Order Number',
        width: 150,
        editable: true,
    },
    {
        field: 'customerName',
        headerName: 'Customer Name',
        width: 150,
        editable: true,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 150,
        editable: true,
    },
    {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        width: 150,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
        editable: true,
    },
    {
        field: 'orderLineItems',
        headerName: 'Line Items',
        width: 300,
        editable: true,
        valueGetter: (params) => {
            const inputData = params.row.orderLineItems
            const condensedFormat = JSON.parse(inputData).map((item: any) => `${item.Name} X${item.Quantity}`).join(", ");
            return `${condensedFormat}`;
        },
    }
];


const OrdersTable = () => {

    const [fetchedOrderData, setFetchedOrderData] = useState([]);
    const [rows, setRows] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(false);

    let { groupId } = useParams();


    useEffect(() => {
        getOrders();
    }, [])

    useEffect(() => {
        generateRows(fetchedOrderData);
    }, [fetchedOrderData])


    const getOrders = async () => {
        setIsDataLoading(true);
        const data = await getOrdersData(groupId as string);
        setFetchedOrderData(data);
        setIsDataLoading(false);



    }


    const generateRows = (orderData: any) => {
        let rowData: any[] = []
        if (orderData.length == undefined || 0) {
            rowData.push({
                id: 1, name: orderData.Name, endTime: orderData.EndTime
            })
        } else {
            for (let i = 0; i < orderData.length; i++) {
                const order = orderData[i].order;
                const customer = orderData[i].customer;
                rowData.push({
                    id: i + 1,
                    status: order.Status.Name,
                    orderNumber: order.OrderNumber,
                    customerName: customer.Name,
                    address: formatAddress(customer.CustomerAddresses[0]),
                    phoneNumber: customer.Phone,
                    email: customer.Email,
                    orderLineItems: JSON.stringify(order.OrderLineItems),
                    customerId: order.CustomerId
                })
            }
        }

        setRows(rowData);
    }

    const formatAddress = (addressObject: any) => {
        const { Address1, Address2, City, Zip } = addressObject
        const fullAddress = `${Address1}, ${Address2}, ${City}, ${Zip} `
        return fullAddress
    }

    const NoRowsOverlay = () => {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No Data To Display
            </Stack>
        );
    }

    return (
        <div className='orders-table-page'>
            {isDataLoading && <LoadingSpinner />}
            <Box sx={{ height: '550px', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay }}
                    pageSizeOptions={[10]}
                    checkboxSelection
                // disableRowSelectionOnClick
                />
            </Box>
        </div>

    );
}

export default OrdersTable;