import './password-change-form.styles.scss';
import { useState, useContext } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { useNavigate } from 'react-router-dom';
import { SignInFormType } from '../../types/context-types/auth.context.types';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthContext } from '../../contexts/auth.context';
import AlertBanner from '../mui/alert.component';
import LoadingSpinner from '../mui/spinner.component';
import { emailRegex } from '../../constants/regex.utils';
import React from 'react';
import companylogo from '../../assets/images/companylogo.png';

// import { CustomerContext } from '../../../contexts/customer.context';
// import { getCustomerData } from '../../../services/api/data.utils';
// import { sendErrorMessageInSlack } from '../../../utils/miscellaneous.functions';



const defaultSignInFields: SignInFormType = {
    email: '',
    password: '',
}

const PasswordChangeForm = () => {
    const [formFields, setFormFields] = useState(defaultSignInFields);
    const [errors, setErrors] = useState<SignInFormType>({ ...defaultSignInFields });
    const { email, password } = formFields;
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    const {
        userSessionObject,
        setSigninData,
        setIsAutenticated,
        // setUserSessionObject, setCognitoUserId
    } = useContext(AuthContext);

    // const { setCustomerData } = useContext(CustomerContext);


    let userSession = null;
    let idToken = null;
    let cognitoUserId: any = null;
    let accessToken = null;
    let jwtToken: any = null;

    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const setCustomerDataAvailable = async () => {

        // let customerData = await getCustomerData(cognitoUserId, jwtToken);
        // setStripeCustomerId(customerData?.data?.CustomerId);
        // localStorage.setItem('stripeCustomerId', customerData?.data?.CustomerId)
        // setCustomerData(customerData.data);
        // return customerData
    }



    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                // let user: CognitoUser
                // user = JSON.parse(localStorage.getItem('userSessionObject') as string);

                let userSessionResponse = await Auth.completeNewPassword(userSessionObject, password, {});
                console.log('ohhh', userSessionResponse)
                navigate('/')
                // userSession = userSessionObject?.getSignInUserSession();
                // idToken = userSession?.getIdToken();
                // cognitoUserId = idToken?.payload['cognito:username'];
                // localStorage.setItem('cognitoUserId', cognitoUserId);
                // accessToken = userSession?.getAccessToken() as any;
                // jwtToken = accessToken?.jwtToken;
                // setCognitoUserId(cognitoUserId);
                // setIsAutenticated(true);
                // setShowAlertSuccess(true);
                // setUserSessionObject(userSessionObject);
                // localStorage.setItem('userSessionObject', JSON.stringify(userSessionObject));
                // setSigninData(formFields);


            } catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in Password change ", error.message)
            }

        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!');
            console.error("Invalid Form");
        }
    }


    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: 'This is a required field' });
        if (email && !emailRegex.test(email)) {
            setErrors({ ...errors, email: 'Email is invalid!' });
        }
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    const validate = () => {
        let tempErrors = { ...defaultSignInFields };
        let hasError = false;
        if (!email) {
            tempErrors.email = "Email is required";
            hasError = true;
        }
        else if (!emailRegex.test(email)) {
            tempErrors.email = "Email is invalid";
            hasError = true;
        }
        if (!password) {
            tempErrors.password = "Password is required";
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='auth-form-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <div className='logo-image-container'>
                <img className='logo-image' src={companylogo} />
            </div>
            <h2>Please update your password! </h2>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='New Password'
                    type='password'
                    onChange={handleChange}
                    name='password'
                    value={password}
                    errorMessage={errors?.password}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='auth-buttons-container'>
                    <FormButton type='submit'>Change Password</FormButton>
                </div>


            </form>
        </div>

    )
}

export default PasswordChangeForm;