

const SamplePage = () => {
    return (
        <div>
            This is sample page. For testing only
        </div>
    )
}

export default SamplePage;