import { SIDE_PANEL_OPTIONS } from '../../constants/data';
import './side-panel.styles.scss';
import { useNavigate } from 'react-router-dom';
import companylogo from '../../assets/images/companylogo.png'
import { useContext } from 'react';
import { TableContext } from '../../contexts/table.context';


const SidePanel = () => {

    const navigate = useNavigate();
    const { setSelectedZoneData } = useContext(TableContext);

    const handleNavigate = (path: string) => {
        if (path === 'delivery') {
            navigate(`/`);
            setSelectedZoneData('Delivery');

        }
        else {
            navigate(`${path}`)
            setSelectedZoneData(path);
        }

    }

    return (
        <div className='sidepanel-container'>
            <div className='sidepanel-image-container'>
                <img className='sidepanel-image' src={companylogo} />
            </div>
            {
                SIDE_PANEL_OPTIONS?.map((option, id) => {
                    return (
                        <div key={id} className='option-container' onClick={() => handleNavigate(option.path)}>
                            <h1>{option.name}</h1>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default SidePanel;