
import './App.css';
import { Routes, Route } from 'react-router-dom'
import MainPage from './routes/main-page/main-page.component';
import Delivery from './routes/delivery-management/delivery.component';
import SignInPage from './routes/sign-in/sign-in-page.component';
import SamplePage from './routes/sample-page/sample-page.component';
import PasswordChangePage from './routes/change-password/password-change.component';
import GroupsPage from './components/groups/groups.component';
import OrdersPage from './components/orders/orders.component';


function App() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}>
        <Route index element={<Delivery />} />
        <Route path='/:zoneID/groups' element={<GroupsPage />} />
        <Route path='/:zoneID/groups/:groupId/orders' element={<OrdersPage />} />

        <Route path='sample-page' element={<SamplePage />} />
      </Route>
      <Route path='/signin' element={<SignInPage />} />
      <Route path='/password-change' element={<PasswordChangePage />} />
    </Routes>
    // <Routes>
    //   <Route path='/dashboard' element={<MainPage />}>
    //     <Route index element={<Delivery />} />
    //     <Route path='sample-page' element={<SamplePage />} />
    //   </Route>
    //   <Route path='/dashboard/signin' element={<SignInPage />} />
    //   <Route path='/dashboard/password-change' element={<PasswordChangePage />} />
    // </Routes>
  );
}

export default App;
