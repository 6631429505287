import { useNavigate } from 'react-router-dom';
import DropdownInput from '../../components/dropdown-input/dropdown-input.component';
import { defaultDropdownID } from '../../constants/other.data';
import { getAllZones } from '../../services/api/data.utils';
import './delivery.styles.scss';
import { useContext, useEffect, useState } from 'react';
import { TableContext } from '../../contexts/table.context';

interface Zone {
    Name: string;
    Id: number;
    [key: string]: any;
}


const Delivery = () => {

    const navigate = useNavigate();
    const [zones, setZones] = useState<Zone[]>([])
    const { setSelectedZoneData } = useContext(TableContext);


    const [selectedZone, setSelectedZone] = useState({
        Name: '',
        Id: defaultDropdownID // Magic number
    });


    useEffect(() => {
        getZoneData();
    }, [])


    const getZoneData = async () => {
        const zoneData = await getAllZones();
        const updatedZoneData = [{ Name: 'All Zones', Id: defaultDropdownID }, ...zoneData]
        setZones(updatedZoneData);
    }



    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];
        const id = Number(selectedOption.dataset.id);
        const name = selectedOption.dataset.name as string;
        setSelectedZone({ Name: name, Id: id });
        setSelectedZoneData(name);
        localStorage.setItem('selectedZoneData', name);
        navigate(`${id}/groups`);
    }

    return (
        <div>
            <div className='delivery-dropdown-container'>
                <h1 className='page-sub-title'>Zone Selection</h1>
                <DropdownInput errorMessage={''} options={zones} name='displayZone' label='Zone' handleDropdownChange={handleDropdownChange} defaultValue={selectedZone.Id} isRequired={true} isHidden={true} />
            </div>

        </div>
    )

}

export default Delivery;