
import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, GridColDef, GridEventListener, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { defaultDropdownID } from '../../../constants/other.data';
import { getAllGroupData, getGroupData } from '../../../services/api/data.utils';
import { useNavigate } from 'react-router-dom';
import { formatDate, getTime } from '../../../utils/date.utils';
import './groups.table.style.scss'
import { TableContext } from '../../../contexts/table.context';
import LoadingSpinner from '../../mui/spinner.component';
import Stack from '@mui/material/Stack';




const columns: GridColDef[] = [
    {
        field: 'zone',
        headerName: 'Zone With Date',
        width: 200,
        editable: true,
        valueGetter: (params) => {
            const formattedDate = formatDate(params.row.startTime)
            return `${params.row.zone || ''} - ${formattedDate || ''}`;
        },
    },
    {
        field: 'name',
        headerName: 'Group Name',
        width: 150,
        editable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        editable: true,
    },
    {
        field: 'city',
        headerName: 'City',
        width: 300,
        editable: true,
    },
    {
        field: 'numberOfOrders',
        headerName: 'Number of Orders',
        width: 150,
        editable: true,
    },
    {
        field: 'capacity',
        headerName: 'Capacity',
        width: 150,
        editable: true,
    },
    {
        field: 'startTime',
        headerName: 'Start Time',
        width: 150,
        editable: true,
        valueGetter: (params) => {
            const formattedDate = getTime(params.row.startTime)
            return `${formattedDate || ''}`;
        },
    }
];


type GroupTableProps = {
    zoneID: string,
}

const GroupsTable = ({ zoneID }: GroupTableProps) => {


    const [fetchedZoneData, setFetchedZoneData] = useState([]);
    const [rows, setRows] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const navigate = useNavigate();

    const { setSelectedGroupData } = useContext(TableContext);

    useEffect(() => {
        getGroups();
    }, [])

    useEffect(() => {
        generateRows(fetchedZoneData);
    }, [fetchedZoneData])


    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        let groupId = params.row.id
        setSelectedGroupData(params.row.name)
        localStorage.setItem('selectedGroupData', params.row.name);
        navigate(`${groupId}/orders`)

    };


    const getGroups = async () => {
        setIsDataLoading(true);
        if (Number(zoneID) === defaultDropdownID) {
            const data = await getAllGroupData()
            setFetchedZoneData(data);
            setIsDataLoading(false)

        } else {
            const data = await getGroupData(zoneID as string);
            setFetchedZoneData(data);
            setIsDataLoading(false)

        }

    }


    const getStatusName = (id: number) => {
        let statusName = ''
        if (id === 0) {
            statusName = 'In Process'
        }
        else if (id === 1) {
            statusName = 'Preparing Order'
        }
        else if (id === 2) {
            statusName = 'Ready For Delivery'
        }
        else if (id === 3) {
            statusName = 'Cancelled'
        }
        else if (id === 4) {
            statusName = 'Balance Pushed'
        }

        return statusName
    }


    const generateRows = (groupData: any) => {
        let rowData: any[] = []
        if (groupData.length == undefined || 0) {
            rowData.push({
                id: groupData.Id, zone: groupData.Zone.Name, name: groupData.Name, capacity: groupData.Capacity, endTime: groupData.EndTime
            })
        } else {
            console.log(groupData)
            for (let i = 0; i < groupData.length; i++) {
                const group = groupData[i];
                rowData.push({
                    id: group.Id,
                    zone: group.Zone.Name,
                    city: group.Zone.Description,
                    numberOfOrders: group.Orders.length,
                    name: group.Name,
                    status: getStatusName(group.Status),
                    capacity: group.Capacity,
                    startTime: group.StartTime
                })
            }
        }
        setRows(rowData);
    }

    const NoRowsOverlay = () => {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No Data To Display
            </Stack>
        );
    }

    return (
        <div className='orders-table-page'>
            {isDataLoading && <LoadingSpinner />}
            <Box sx={{height: '550px', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar, noRowsOverlay: NoRowsOverlay  }}
                    onRowClick={handleRowClick}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>

    );
}

export default GroupsTable;