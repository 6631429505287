import PasswordChangeForm from '../../components/password-change-form/password-change-form.component';
import './password-change.styles.scss';


const PasswordChangePage = () => {
    return (
        <div>
            <PasswordChangeForm/>
        </div>
    )
}

export default PasswordChangePage;