import axios, { AxiosResponse } from "axios";


let ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string;
let customersBaseURL = process.env.REACT_APP_CUSTOMERS_BASE_URL as string;

export const getData = async <T>(
  url: string
): Promise<AxiosResponse<T> | undefined> => {
  const response = await axios.get(url);
  return response;
};

export const getAuthorizedData = async <T>(
  url: string,
  token: string
): Promise<AxiosResponse<T> | any> => {
  try {
    const response = await axios.get<T>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e: any) {
    console.log(e, "Error in getting data from auth endpoints");
    return e.response.status;
  }
};

export const getAllZones = async (): Promise<any> => {
  const allZoneResponse = await getData(`${ordersBaseURL}zones`);
  return allZoneResponse?.data;
};

export const getGroupData = async (zoneId: string): Promise<any> => {
  console.log('get single group data ==> ', `${ordersBaseURL}groups/zone/${zoneId}`);
  const groupData = await getData(`${ordersBaseURL}groups/zone/${zoneId}`);
  return groupData?.data;
};

export const getAllGroupData = async (): Promise<any> => {
  console.log('get all group data ==> ', `${ordersBaseURL}groups`);
  const allGroupData = await getData(`${ordersBaseURL}groups`);
  return allGroupData?.data;
};

export const getOrdersData = async (groupId: string): Promise<any> => {
  const ordersData = await getData(`${ordersBaseURL}orders/group/${groupId}`);
  return ordersData?.data;
};


