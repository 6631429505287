import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../../contexts/auth.context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const navigate = useNavigate();


    const {
        setIsAutenticated, userSessionObject
    } = useContext(AuthContext);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sendLogoutDetails = async () => {

        let userSession = userSessionObject?.getSignInUserSession();
        let idToken = userSession?.getIdToken();
        let cognitoUserId = idToken?.payload['cognito:username'];
        let email = idToken?.payload['email']
        const dataToSend = {
            UserId: cognitoUserId,
            Email:email,
            Description: "Logout",
            ActivityDate: new Date().toISOString()
        }

        await axios.post('https://play.svix.com/in/e_fdsUSucJ6xgNcJff9VrsY11ynIq/', dataToSend)
        await axios.post('https://dtbbihspqa.us-east-1.awsapprunner.com/users/logs', dataToSend)
    }

    const handleLogout = () => {
        setAnchorEl(null);
        try {
            Auth.signOut();
            setIsAutenticated(false);
            sendLogoutDetails();
            localStorage.clear();
            sessionStorage.clear();
            navigate('/signin');

        } catch (error) {
            console.log("Error in SignOut ", error)
        }
    };

    const handleSignOut = () => {
        try {
            Auth.signOut();
            setIsAutenticated(false);
            localStorage.clear();
            sessionStorage.clear();


        } catch (error) {
            console.log("Error in SignOut ", error)
        }
    }
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32, bgcolor:'rgba(33, 111, 189, 0.947)', padding: '20px' }}>SH</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Avatar /> My account
                </MenuItem>
                <Divider /> */}
                {/* <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
