import SignInForm from '../../components/signin-form/sign-in-form.component';
import './sign-in-page.styles.scss';

const SignInPage = () => {
    return (
        <div>
            <SignInForm/>
        </div>
    )
}

export default SignInPage;