

export const SIDE_PANEL_OPTIONS = [

    {
        id: 1,
        name: 'DELIVERY',
        path:'delivery'

    }
]