import { useContext, useEffect, useState } from 'react';
import './header-bar.styles.scss';
import { AuthContext } from '../../contexts/auth.context';
import { TableContext } from '../../contexts/table.context';
import AccountMenu from '../account-menu/account-menu.component';

const HeaderBar = () => {

    let userSession = null;
    let idToken = null;
    let email: string;

    const {pageTitle, setPageTitle} = useContext(TableContext)
    // const { userSessionObject } = useContext(AuthContext);
    // const [headerMessage, setHeaderMessage] = useState('')

    useEffect(() => {
        // console.log(userSessionObject, '#')
        // userSession = userSessionObject?.getSignInUserSession();
        // idToken = userSession?.getIdToken();
        // email = idToken?.payload['email'];
        // setHeaderMessage(`Hi ${email} ..`)
    }, [])



    return (
        <div className='headerbar-container'>
            <h1 className='headerbar-title'>{pageTitle}</h1>
            <AccountMenu/>
        </div>
    )
}

export default HeaderBar;