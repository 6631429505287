import { defaultDropdownID } from '../../constants/other.data';
import './dropdown-input.styles.scss';
import React from 'react';

type DropdownInputProps = {
    name?: string,
    // id: number,
    defaultValue?: number | string,
    isRequired?: boolean,
    isHidden?: boolean,
    label?: string,
    errorMessage?: string,
    handleDropdownChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    options?: Options[]

} & React.ComponentProps<'select'>

type BasicOptions = { Name: string, Id: number }

type Options = BasicOptions


const DropdownInput = ({ options, label, handleDropdownChange, name, defaultValue, errorMessage }: DropdownInputProps) => {

    const sortOptions = [...options as Options[]].sort((a, b) =>
        ((a as BasicOptions).Name.toLowerCase()).localeCompare((b as BasicOptions).Name.toLowerCase())
    );

    return (
        <div className='select-input-container'>
            <select className={`select-input ${errorMessage ? 'error' : null}`} onChange={handleDropdownChange} name={name} value={defaultValue}>
                <option key={-1} data-id={defaultDropdownID} data-name={'Select an option'} value={defaultDropdownID} disabled>
                    Select a zone
                </option>
                {sortOptions?.map((option) => {
                    const { Name, Id } = option as BasicOptions;
                    return (
                        <option key={Id} data-id={Id} data-name={Name} value={Id}>
                            {Name}
                        </option>
                    );
                })}

            </select>
            {/* <label className='shrink select-input-label'>{label}</label> */}

            {errorMessage && <p className='error-message'>{errorMessage}</p>}
        </div>

    )
}

export default DropdownInput;