import { useContext } from 'react';
import OrdersTable from '../tables/orders-table/orders.table.component';
import './orders.styes.scss';
import { TableContext } from '../../contexts/table.context';

const OrdersPage = () => {

    const { selectedGroupData } = useContext(TableContext);

    return (
        <div className='groups-container'>
            <h1>{selectedGroupData || localStorage.getItem('selectedGroupData')}</h1>
            <OrdersTable />
        </div>
    )

}

export default OrdersPage;